.ChannelSection__title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 0 12px;
}

.adm-collapse-panel-content
  > .adm-collapse-panel-content-inner
  > .adm-list-item
  > .adm-list-item-content
  > .adm-list-item-content-main {
  padding: 0;
}

.ChannelSection__list {
  --border-top: 0;
  --border-bottom: 0;
}
