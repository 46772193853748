.dialogs {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dialogs-title {
  text-transform: uppercase;
  font-weight: 600;
}

.dialogs .adm-nav-bar {
  padding: 0;
}
